import React, { useEffect, useState } from 'react';
import { ContactsDTO, SessionDTO, ContactsWithLastCallResultDTO } from '../../@types';

import "./Calls.css";
import { countContacts, fetchContacts } from '../../Controllers/Contacts';
import { Button, Flex, FlexItem, Heading, Icon, Input, TableBuilder } from '../../../HoosatUI';
import { useTranslation } from 'react-i18next';

interface ListContactsProps {
  session: SessionDTO;
  setCurrentComponent: React.Dispatch<React.SetStateAction<string>>;
  setSelectedContact: React.Dispatch<React.SetStateAction<ContactsDTO>>;
  selectedContact: ContactsDTO;
}

export const ListContacts: React.FC<ListContactsProps> = (props: ListContactsProps) => {
  const loadPaginationFromLocalStorage = () => {
    const storedPagination = localStorage.getItem('contacts-pagination');
    if (storedPagination) {
      const parsedPagination = JSON.parse(storedPagination);
      return parsedPagination;
    } else {
      return {
        skip: 0,
        limit: 10
      };
    }
  }
  const loadSearchFromLocalStorage = () => {
    const storedSearch = localStorage.getItem('contacts-search');
    console.log(storedSearch);
    if (storedSearch) {
      const parsedSearch = JSON.parse(storedSearch);
      return parsedSearch;
    } else {
      return "";
    }
  }
  const loadRequiredFromLocalStorage = () => {
    const storedRequired = localStorage.getItem('contacts-required');
    if (storedRequired) {
      const parsedRequired = JSON.parse(storedRequired);
      return parsedRequired;
    } else {
      return "phone";
    }
  }

  const [t] = useTranslation();
  const [contacts, setContacts] = useState<ContactsWithLastCallResultDTO[]>([]);
  const [required, setRequired] = useState<string>(loadRequiredFromLocalStorage());
  const [search, setSearch] = useState<string>(loadSearchFromLocalStorage());
  const [pagination, setPagination] = useState(loadPaginationFromLocalStorage());
  const [pageNumber, setPageNumber] = useState<string>("0");


  useEffect(() => {
    const getContacts = async () => {
      const fetchedContacts = await fetchContacts(props.session, search, pagination.skip, pagination.limit);
      setContacts(fetchedContacts);
      setPageNumber((pagination.skip / pagination.limit).toString());
    }
    getContacts();
  }, [pagination]);

  const searchChange = (e: React.BaseSyntheticEvent) => {
    localStorage.setItem("contacts-search", JSON.stringify(e.target.value));
    setSearch(e.target.value);
  }

  const submitSearch = async () => {
    const fetchedContacts = await fetchContacts(props.session, search, pagination.skip, pagination.limit);
    setContacts(fetchedContacts);
    setPageNumber((pagination.skip / pagination.limit).toString());
  }

  const paginateNextContacts = async () => {
    const updatedPagination = {
      ...pagination,
      skip: pagination.skip + pagination.limit,
    }
    localStorage.setItem("contacts-pagination", JSON.stringify(updatedPagination));
    localStorage.setItem("contacts-search", JSON.stringify(search));
    localStorage.setItem("contacts-required", JSON.stringify(required));
    setPagination(updatedPagination);
  }

  const paginatePrevContacts = async () => {
    if (pagination.skip > pagination.limit) {
      const updatedPagination = {
        ...pagination,
        skip: pagination.skip - pagination.limit,
      }
      localStorage.setItem("contacts-pagination", JSON.stringify(updatedPagination));
      setPagination(updatedPagination);
    } else if (pagination.skip === pagination.limit) {
      const updatedPagination = {
        ...pagination,
        skip: 0,
      }
      localStorage.setItem("contacts-pagination", JSON.stringify(updatedPagination));
      setPagination(updatedPagination);
    }
  }

  const pageChange = (e: React.BaseSyntheticEvent) => {
    if (parseFloat(e.target.value) >= 0) {
      const updatedPagination = {
        skip: parseFloat(e.target.value) * pagination.limit,
        limit: pagination.limit
      }
      localStorage.setItem("contacts-pagination", JSON.stringify(updatedPagination));
      setPagination(updatedPagination);
      setPageNumber(e.target.value);
    }
  }

  return (
    <>
      <Flex id="list-options">
        <FlexItem>
          <Heading variant='h1'>{t("calls.header")}</Heading>
        </FlexItem>
        <FlexItem>
          <FlexItem>
            <label htmlFor="search-input" className="InputLabel">{t("calls.list-options.search")}</label>
          </FlexItem>
          <FlexItem className='list-options-search'>
            <input type="text" id="search-input" className="Input primary" value={search} onChange={searchChange}></input>
            <Button onClick={submitSearch}>{t("calls.list-options.search-submit")}</Button>
          </FlexItem>
        </FlexItem>
      </Flex>
      <TableBuilder
        className='contacts-table'
        headers={[
          t("calls.list-options.call"),
          t("calls.list-options.edit"),
          <span onClick={() => { setSearch("name=") }}>{t("calls.list-options.name")}</span>,
          <span onClick={() => { setSearch("ycode=") }}>{t("calls.list-options.ycode")}</span>,
          <span onClick={() => { setSearch("bcodes=") }}>{t("calls.list-options.bcodes")}</span>,
          <span onClick={() => { setSearch("registered=") }}>{t("calls.list-options.registered")}</span>,
          <span onClick={() => { setSearch("phone=") }}>{t("calls.list-options.phone")}</span>,
          <span onClick={() => { setSearch("web=") }}>{t("calls.list-options.web")}</span>,
          <span onClick={() => { setSearch("lighthouse=") }}>{t("calls.list-options.lighthouse")}</span>,
          <span onClick={() => { setSearch("street=") }}>{t("calls.list-options.street")}</span>,
          <span onClick={() => { setSearch("post=") }}>{t("calls.list-options.post")}</span>,
          <span onClick={() => { setSearch("city=") }}>{t("calls.list-options.city")}</span>,
          <span onClick={() => { setSearch("email=") }}>{t("calls.list-options.email")}</span>,
        ]}
        rows={
          contacts.map(contact => ({
            _id: contact._id,
            color: (contact.resultType === "POSITIVE") ? "#198754" : (contact.resultType === "NEGATIVE") ? "#DC3545" : (contact.resultType === "NEUTRAL") ? "#8B694D" : (contact.result.length > 0) ? "#D29038" : "#F6F3F3",
            data: {
              call: <Button className="w-full" onClick={(_e: React.BaseSyntheticEvent) => {
                props.setSelectedContact(contact);
                props.setCurrentComponent("calls.caller");
              }}><Icon className="icon" name="phone" /></Button>,
              edit: <Button className="w-full" onClick={(_e: React.BaseSyntheticEvent) => {
                props.setSelectedContact(contact);
              }}><Icon className="icon" name="user" /></Button>,
              name: contact.name,
              ycode: contact.ycode,
              bcodes: contact.bcodes,
              registered: contact.registered,
              phone: contact.phone,
              web: <a href={contact.web} target='_blank' rel="noreferrer noopener">{contact.web}</a>,
              lighthouse: (typeof (contact.lighthouse) === "string") ? contact.lighthouse : (contact.lighthouse?.speedIndex !== undefined) ? contact.lighthouse.speedIndex : "",
              street: contact.street,
              post: contact.post,
              city: contact.city,
              email: contact.email,
            },
            onClick: () => {
              props.setSelectedContact(contact);
            }
          }))
        }
      />
      <Flex className="pagination">
        <FlexItem>
          {(pagination.skip > 0) &&
            <Button onClick={paginatePrevContacts}> <Icon className="icon" name="fast-rewind" /></Button>
          }
        </FlexItem>
        <FlexItem>
          <Input type="number" onChange={pageChange} value={pageNumber} />
        </FlexItem>
        <FlexItem>
          <Button onClick={paginateNextContacts}><Icon className="icon" name="fast-forward" /></Button>
        </FlexItem>
      </Flex>
    </>
  );
} 